import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import KeenUI from "keen-ui";
import "keen-ui/dist/keen-ui.css";
import "promise-decode-audio-data";
import "./registerServiceWorker";

const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
disableBodyScroll(document.querySelector("#app"));

Vue.use(KeenUI);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
