<template>
  <div id="app">
    <ui-toolbar
      title="HRTFApp"
      type="colored"
      :raised="true"
      text-color="white"
      :removeNavIcon="true"
      :loading="loading"
    ></ui-toolbar>
    <ui-tabs
      background-color="clear"
      fullwidth="fullwidth"
      indicator-color="primary"
      :raised="false"
    >
      <ui-tab key="POSITION" :selected="true" title="POSITION">
        <div
          style="display: flex; justify-content: center; align-items: center;"
        >
          <knob
            :input="heading"
            v-on:value="position = $event"
            :config="{ ...hrtfKnobOption, readOnly: compassState }"
          />
        </div>
      </ui-tab>
      <ui-tab key="SPACE" title="SPACE">
        <div
          style="display: flex; justify-content: center; align-items: center;"
        >
          <knob
            v-on:value="reverbAmount = $event"
            :config="reverbKnobOptions"
          />
        </div>
      </ui-tab>
      <ui-tab v-if="compassSupport" key="HEADING" title="HEADING">
        <div
          class="card"
          style="display: flex; justify-content: center; align-items: center;"
        >
          <ui-switch v-model="compassState" :value="compassState">
            Turn
            <span v-if="!compassState">on</span>
            <span v-if="compassState">off</span> heading
          </ui-switch>
        </div>
      </ui-tab>
    </ui-tabs>
    <!-- <div
      class="card"
      style="display:flex;justify-content:center; align-items:center"
    >
      <ui-slider
        icon="volume_up"
        :step="10"
        :min="0"
        :max="100"
        :value="volume"
        v-on:input="volume = $event"
      ></ui-slider>
    </div>-->
    <div
      class="card"
      style="display: flex; justify-content: center; align-items: center;"
    >
      <span v-if="!sounds.length">Loading Sounds ...</span>
      <ui-button
        v-for="(sound, index) in sounds"
        @click="playSound(sounds[index])"
        class="sound"
        :color="`${sound.isPlaying ? 'primary' : 'default'}`"
        :raised="sound.isPlaying"
        :key="sound.name"
        >{{ sound.name }}</ui-button
      >
    </div>
  </div>
</template>

<script>
import Knob from "@/components/Knob";
import Tuna from "tunajs";
import BinauralFIR from "binauralfir/dist/binaural-fir.js";
import Compass from "@/lib/compass";
import loadAsync from "@/lib/loadAsync";

export default {
  components: {
    Knob
  },
  data: () => ({
    tabs: [
      {
        title: "HRTF"
      },
      {
        title: "REVERB"
      }
    ],
    hrtfKnobOption: {
      skin: {
        type: "tron",
        width: 5,
        color: "#494B52",
        spaceWidth: 3
      },
      scale: {
        enabled: false,
        type: "lines",
        color: "rgba(0,0,0,.2)",
        width: 2,
        quantity: 72,
        spaceWidth: 10,
        height: 8
      },
      bgColor: "#fff",
      barColor: "#2196f3",
      textColor: "#000",
      trackWidth: 20,
      barWidth: 30,
      min: 0,
      max: 365,
      unit: "°",
      subText: {
        enabled: false,
        text: "Position"
      }
    },
    reverbKnobOptions: {
      min: 0,
      max: 100,
      unit: "%",
      subText: {
        enabled: false,
        text: "Reverb"
      }
    },
    reverbAmount: 0,
    position: 0,
    volume: 80,
    audioContext: null,
    input: null,
    output: null,
    gainNode: null,
    binauralNode: null,
    breakbeat: null,
    tuna: null,
    reverb: null,
    reverbIR: null,
    sounds: [],
    compass: null,
    compassSupport: false,
    compassWatchID: null,
    compassState: false,
    heading: 0,
    loading: true,
    test: 0
  }),
  computed: {
    compassAvailable: function() {
      return this.compassSupport;
    }
  },
  async created() {
    try {
      this.loading = true;
      this.compass = new Compass();
      this.setupCompass();
      this.audioContext = await this.createAudioContext();
      const HRTFData = await this.bufferHRTF(this.audioContext);
      this.setupAudioPath(this.audioContext, HRTFData);
      await Promise.all([
        this.loadSound("breakbeat", "/breakbeat.m4a"),
        this.loadSound("ping", "/ping.m4a"),
        this.loadSound("clock", "/clock.m4a")
      ]);
      console.log("all sounds loaded");
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  },
  watch: {
    reverbAmount: function(newVal) {
      this.reverb.wetLevel = newVal / this.reverbKnobOptions.max;
    },
    position: function(newVal) {
      if (this.binauralNode) {
        this.binauralNode.setPosition(
          newVal,
          this.binauralNode.getPosition().elevation,
          1
        );
      }
    },
    volume: function(newVal) {
      console.log(newVal);
      this.gainNode.gain.value = newVal / 100;
    },
    heading: function(newVal) {
      console.log(newVal);
      this.binauralNode.setPosition(
        newVal,
        this.binauralNode.getPosition().elevation,
        1
      );
    },
    compassState: function() {
      this.toggleCompass();
    }
  },
  methods: {
    loadSound: async function(name, url) {
      console.log("Loading sound from " + url);
      let sound = { name: name, isPlaying: false };
      try {
        const request = new Request(url);
        const response = await fetch(request);
        const buffer = await response.arrayBuffer();
        const decodedData = await this.audioContext.decodeAudioData(buffer);
        sound.buffer = decodedData;
        this.sounds.push(sound);
      } catch (error) {
        console.log("error loading sounds", error);
      }
    },
    bufferHRTF: async function(audioContext) {
      console.log("loading hrtf");
      const request = new Request("./HRTF.json");
      const response = await fetch(request);
      const hrtfs = await response.json();
      for (let i = 0; i < hrtfs.length; i++) {
        const buffer = audioContext.createBuffer(
          2,
          512,
          this.audioContext.sampleRate
        );
        let bufferChannelLeft = buffer.getChannelData(0);
        let bufferChannelRight = buffer.getChannelData(1);
        for (let e = 0; e < hrtfs[i].fir_coeffs_left.length; e++) {
          bufferChannelLeft[e] = hrtfs[i].fir_coeffs_left[e];
          bufferChannelRight[e] = hrtfs[i].fir_coeffs_right[e];
        }
        hrtfs[i].buffer = buffer;
      }
      return hrtfs;
    },
    createAudioContext: async function() {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      await audioContext.suspend();
      return audioContext;
    },
    setupAudioPath: function(context, hrtfs) {
      console.log("setup audio path");
      console.log("Creating Tuna");
      this.tuna = new Tuna(this.audioContext);
      this.reverb = new this.tuna.Convolver({
        highCut: 22050,
        lowCut: 20,
        dryLevel: 1,
        wetLevel: 0,
        level: 1,
        impulse: "/room5.m4a",
        bypass: 0
      });

      console.log("Creating BinauralFIR Node ...");
      this.binauralNode = new BinauralFIR({
        audioContext: this.audioContext
      });

      console.log("Setting Dataset ...");
      this.binauralNode.HRTFDataset = hrtfs;

      this.binauralNode.setPosition(0, 0, 1);

      console.log("creating gain ...");
      this.gainNode = this.audioContext.createGain();
      this.gainNode.gain.value = this.volume / 100;

      console.log("Creating path ...");
      this.input = this.audioContext.createBufferSource();
      this.output = this.audioContext.destination;

      // this.input.connect(this.gainNode);
      this.gainNode.connect(this.binauralNode.input);
      this.binauralNode.connect(this.reverb);
      this.reverb.connect(this.output);
    },
    playSound: function(soundToPlay) {
      console.log("Playing back " + soundToPlay.name);
      if (this.input) {
        this.input.disconnect();
      }
      this.startAudioEngine();

      this.input = this.audioContext.createBufferSource();
      this.input.buffer = soundToPlay.buffer;
      this.input.loop = true;
      this.input.connect(this.gainNode);
      this.input.start(0);
      this.sounds = this.sounds.map(sound => {
        if (sound !== soundToPlay) {
          sound.isPlaying = false;
        } else {
          sound.isPlaying = true;
        }
        return sound;
      });
    },
    startAudioEngine: async function() {
      await this.audioContext.resume();
    },
    stopAudioEngine: async function() {
      await this.audioContext.suspend();
    },
    toggleAudioEngine: async function() {
      console.log("starting audio engine!");
      if (this.audioContext.state === "running") {
        await this.audioContext.suspend();
        console.log("suspended", this.audioContext.state);
      } else {
        await this.audioContext.resume();
        console.log("resumed", this.audioContext.state);
      }
    },
    setupCompass: function() {
      this.compass.init(method => {
        console.log("Compass: Heading by " + method);
        if (!method) {
          console.log("Compass: try again!");
          this.compass.init(function() {});
        } else {
          console.log("Compass: setting compass support");
          this.compassSupport = true;
        }
      });

      this.compass.noSupport(() => {
        console.log("Compass: no support ");
        this.compassSupport = false;
      });
    },
    toggleCompass: function() {
      if (this.compassWhatchID) {
        this.compass.unwatch(this.compassWhatchID);
        this.compassWhatchID = null;
      } else {
        this.compassWhatchID = this.compass.watch(heading => {
          let deg = Math.floor(heading);
          deg = 360 - (deg % 360);
          this.heading = deg;
          console.log(this.heading);
        });
      }
    }
  }
};
</script>

<style lang="scss">
.knob {
  display: flex;
  justify-content: center;
}
.ui-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.ui-tabs__header {
  box-shadow: 0px 3px 12px -1px #ccc;
}
.card {
  box-shadow: 0px 3px 12px -1px #ccc;
  padding: 25px;
  margin: 0 15px;
  margin-bottom: 1.5em;
  > * {
    flex: 1;
  }
}
.ui-tabs__body {
  margin-top: 1.5em !important;
  border: none !important;
  padding: 0 !important;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
