<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  }
};
</script>
<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
  user-select: none;
}
#app {
  display: flex;
  flex-direction: column;
  height: 90vh;
}
@media (min-width: 600px) and (orientation: landscape) {
  body {
    margin: 10% auto;
    max-width: 360px;
    background-color: #ccc;
    padding-bottom: 20px;
  }
  #app {
    background-color: white;
    height: 600px;
  }
}
</style>
