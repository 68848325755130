export default class Compass {
  constructor() {
    // If browser hasn’t access to compass, `method` will be `false`.
    this.method = undefined;

    // Detect compass method and execute `callback`, when library will be
    // initialized. Callback will get method name (or `false` if library can’t
    // detect compass) in first argument.
    //
    // It is best way to check `method` property.
    //
    //   Compass.init(function (method) {
    //     console.log('Compass by ' + method);
    //   });

    // Last watch ID.
    this._lastId = 0;

    // Hash of internal ID to watcher to use it in `unwatch`.
    this._watchers = {};

    // List of callbacks.
    this._callbacks = {
      // Callbacks from `init` method.
      init: [],

      // Callbacks from `noSupport` method.
      noSupport: [],

      // Callbacks from `needGPS` method.
      needGPS: [],

      // Callbacks from `needMove` method.
      needMove: []
    };
    // Is library now try to detect compass method.
    this._initing = false;
    // Difference between `alpha` orientation and real North from GPS.
    this._gpsDiff = undefined;
    // Tell, that we wait for `DeviceOrientationEvent`.
    this._checking = false;
  }
  // Shortcut to check, that `letiable` is not `undefined` or `null`.
  defined(variable) {
    return variable != null || variable != undefined;
  }
  // Fire `type` callbacks with `args`.
  fire(type, args) {
    let callbacks = this._callbacks[type];
    for (let i = 0; i < callbacks.length; i++) {
      callbacks[i].apply(window, args);
    }
  }
  // Calculate average value for last 5 `array` items;
  average5(array) {
    let sum = 0;
    for (let i = array.length - 1; i > array.length - 6; i--) {
      sum += array[i];
    }
    return sum / 5;
  }
  // Finish library initialization and use `method` to get compass heading.
  _start(method) {
    this.method = method;
    this._initing = false;

    this.fire("init", [method]);
    this._callbacks.init = [];

    if (method === false) {
      this.fire("noSupport", []);
    }
    this._callbacks.noSupport = [];
  }
  init(callback) {
    if (this.defined(this.method)) {
      callback(this.method);
      return;
    }
    this._callbacks.init.push(callback);

    if (this._initing) {
      return;
    }
    this._initing = true;

    if (navigator.compass) {
      this._start("phonegap");
    } else if (window.DeviceOrientationEvent) {
      this._checking = 0;
      window.addEventListener("deviceorientation", this._checkEvent.bind(this));
      setTimeout(
        function() {
          if (this._checking !== false) {
            this._start(false);
          }
        }.bind(this),
        500
      );
    } else {
      this._start(false);
    }
  }
  // Watch for compass heading changes and execute `callback` with degrees
  // relative to magnetic north (from 0 to 360).
  //
  // Method return watcher ID to use it in `unwatch`.
  //
  //   let watchID = Compass.watch(function (heading) {
  //     $('.degrees').text(heading);
  //     // Don’t forget to change degree sign, when rotate compass.
  //     $('.compass').css({ transform: 'rotate(' + (-heading) + 'deg)' });
  //   });
  //
  //   someApp.close(function () {
  //     Compass.unwatch(watchID);
  //   });
  watch(callback) {
    let id = ++this._lastId;

    this.init(
      function(method) {
        if (method == "phonegap") {
          this._watchers[id] = navigator.compass.watchHeading(callback);
        } else if (method == "webkitOrientation") {
          let watcher = function(e) {
            callback(e.webkitCompassHeading);
          };
          window.addEventListener("deviceorientation", watcher);
          this._watchers[id] = watcher;
        } else if (method == "orientationAndGPS") {
          let degrees;
          let watcher = function(e) {
            degrees = -e.alpha + this._gpsDiff;
            if (degrees < 0) {
              degrees += 360;
            } else if (degrees > 360) {
              degrees -= 360;
            }
            callback(degrees);
          };
          window.addEventListener("deviceorientation", watcher);
          this._watchers[id] = watcher;
        }
      }.bind(this)
    );

    return id;
  }

  // Remove watcher by watcher ID from `watch`.
  //
  //   Compass.unwatch(watchID)
  unwatch(id) {
    this.init(
      function(m) {
        if (m == "phonegap") {
          navigator.compass.clearWatch(this._watchers[id]);
        } else if (m == "webkitOrientation" || m == "orientationAndGPS") {
          window.removeEventListener("deviceorientation", this._watchers[id]);
        }
        delete this._watchers[id];
      }.bind(this)
    );
    return this;
  }

  // Execute `callback`, when GPS hack activated to detect difference between
  // device orientation and real North from GPS.
  //
  // You need to show to user some message, that he must go outside to be able
  // to receive GPS signal.
  //
  // Callback must be set before `init` or `watch` executing.
  //
  //   Compass.needGPS(function () {
  //     $('.go-outside-message').show();
  //   });
  //
  // Don’t forget to hide message by `needMove` callback in second step.
  needGPS(callback) {
    this._callbacks.needGPS.push(callback);
    return this;
  }

  // Execute `callback` on second GPS hack step, when library has GPS signal,
  // but user must move and hold the device straight ahead. Library will use
  // `heading` from GPS movement tracking to detect difference between
  // device orientation and real North.
  //
  // Callback must be set before `init` or `watch` executing.
  //
  //   Compass.needMove(function () {
  //     $('.go-outside-message').hide()
  //     $('.move-and-hold-ahead-message').show();
  //   });
  //
  // Don’t forget to hide message in `init` callback:
  //
  //   Compass.init(function () {
  //     $('.move-and-hold-ahead-message').hide();
  //   });
  needMove(callback) {
    this._callbacks.needMove.push(callback);
    return this;
  }

  // Execute `callback` if browser hasn’t any way to get compass heading.
  //
  //   Compass.noSupport(function () {
  //     $('.compass').hide();
  //   });
  //
  // On Firefox detecting can take about 0.5 second. So, it will be better
  // to show compass in `init`, than to hide it in `noSupport`.
  noSupport(callback) {
    if (this.method === false) {
      callback();
    } else if (!this.defined(this.method)) {
      this._callbacks.noSupport.push(callback);
    }
    return this;
  }

  // Check `DeviceOrientationEvent` to detect compass method.
  _checkEvent(e) {
    this._checking += 1;
    let wait = false;

    if (this.defined(e.webkitCompassHeading)) {
      this._start("webkitOrientation");
    } else if (this.defined(e.alpha) && navigator.geolocation) {
      this._gpsHack();
    } else if (this._checking > 1) {
      this._start(false);
    } else {
      wait = true;
    }

    if (!wait) {
      this._checking = false;
      window.removeEventListener("deviceorientation", this._checkEvent);
    }
  }

  // Use GPS to detect difference  between `alpha` orientation and real North.
  _gpsHack() {
    let first = true;
    let alphas = [];
    let headings = [];

    this.fire("needGPS");

    let saveAlpha = function(e) {
      alphas.push(e.alpha);
    };
    window.addEventListener("deviceorientation", saveAlpha);

    let success = function(position) {
      let coords = position.coords;
      if (!this.defined(coords.heading)) {
        return; // Position not from GPS
      }

      if (first) {
        first = false;
        this.fire("needMove");
      }

      if (coords.speed > 1) {
        headings.push(coords.heading);
        if (headings.length >= 5 && alphas.length >= 5) {
          window.removeEventListener("deviceorientation", saveAlpha);
          navigator.geolocation.clearWatch(watcher);

          this._gpsDiff = this.average5(headings) + this.average5(alphas);
          this._start("orientationAndGPS");
        }
      } else {
        headings = [];
      }
    };
    let error = function() {
      window.removeEventListener("deviceorientation", saveAlpha);
      this._start(false);
    };

    let watcher = navigator.geolocation.watchPosition(success, error, {
      enableHighAccuracy: true
    });
  }
}
